div.section {
    &:first-of-type {
        margin-top: 30px;
    }
    margin-bottom: 30px;
    opacity: 1;
    &.hidden {
        opacity: 0;
    }
    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 100ms;
    }
}

p{
    font-size: 18px;
}

.css-1f961nr{
    max-width: 600px;
}

// @media screen and (max-width:732px){
//     .custom-modal{
//         .css-1a4jrhd{
//             padding: 0px !important;
//             display: block;
//         }
//         .css-olxp6i{
//             font-size: 1rem;
//             line-height: 1.2;
//             margin-bottom: 10px;
//         }
//         .css-5mrmps{
//             font-size: .6rem;
//             line-height: 1;
//         }
//         .css-1jwvbdk{
//             padding: 5px 12px;
//             font-size: .5rem;
//             min-width: 80px;
//         }
//         .css-5jmd9i{
//             padding: 5px 12px;
//             font-size: .5rem;
//             min-width: 80px;
//         }
//     }
// }

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

kat-button[data-next] {
    float: right;
}

.wait {
    align-items: center;
    display: flex;
    justify-content: center;
}

// This is awkward but Katal styles breaks the Stencil Pagination component style and which doesn't provide a prop to customize the styles.
// We need to figure of how to customize it later.
.katal nav {
    display: flex !important;
    justify-content: center;
}
.katal label {
    font-size: 14px;
    font-weight: bold;
}
