
html {
    width: 100%;
    height: 100%;
    display: table;
    font-size: 10px !important;
}

body {
    width: 100%;
    height: 100%;
    display: table-cell;
    font-size: 14px !important;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden
}

#root {
    height: 100%;
    display: flex;
    padding-top: 16px;
}

p{
    font-size: 18px !important;
}

h1{
    font-size: 30px !important;
    line-height: 32px !important;
    font-weight: 500 !important;
}

h2{
    font-size: 24px !important;
}

h3{
    font-size: 18.5px !important;
}

h4{
    font-size: 17.5px !important;
}

h5{
    font-size: 15.5px !important;
}

li{
    font-size: 16px !important;
}

li {
    button {
        div {
            font-size: 16px !important;
        }
    }
}

body {
    margin: 0px;
}
.css-1oqepr3 {
    font-size: 14px !important;
    line-height: 22px !important;
    font-weight: 400 !important;
}

div.section {
    &:first-of-type {
        margin-top: 30px;
    }
    margin-bottom: 30px;
    opacity: 1;

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 100ms;
    }
}

.foo { flex: 1; }

.profileAgLogo{
    max-height: 50px;
    width: 100%
}

.video-page {
    padding: 16px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.float-right {
    float: right;
}

.marker {
    background-color: yellow;
}

a {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
} 

a:hover {
    text-decoration: underline !important;
}


@media screen and (max-width:430px){
    .custom-modal{
        .css-1a4jrhd{
            padding: 0px !important;
            display: block;
        }
        .css-olxp6i{
            font-size: 22px;
            line-height: 32px;
            margin-bottom: 10px;
        }
        .css-5mrmps{
            font-size: 16px;
            line-height: 24px;
        }
        .css-1jwvbdk{
            padding: 5px 12px;
            font-size: 16px;
        }
        .css-5jmd9i{
            padding: 5px 12px;
            font-size: 16px;
        }
    }
    .profileAgLogo{
            max-height: 120px;
    }

    .video-block{
        max-width: 100%;
        width: 100%;
    }

    .video-page {
        padding: 0px !important;
    }

    html, body {
        margin: 0;
        padding: 0;
        overflow-x: hidden
    }
    img {
        width: 100%
    }
}